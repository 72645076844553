import React from 'react';
import styled from 'react-emotion';

import BackButton from '../shared/BackButton';
import {Button} from '../shared/Buttons'
import InterfaceContext from '../../context/InterfaceContext';
import {
  Heading as BaseHeading,
  TextContainer,
  UnorderedList
} from '../shared/Typography';
import { colors, spacing, dimensions, breakpoints } from '../../utils/styles';

const Heading = styled(BaseHeading)`
  margin-bottom: -${spacing.sm}px;
`;

const ProductTextContainer = styled(TextContainer)`
  padding: ${spacing.xl}px;
`;

const Section = styled(`section`)`
  padding-top: calc(${dimensions.headerHeight} + ${spacing.sm}px);
`;

const SectionHeading = styled(Heading.withComponent(`h2`))`
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  margin-bottom: ${spacing.sm}px;
`;

const SubHeading = styled(Heading.withComponent(`h3`))`
  color: ${colors.text};
  font-size: 1.2rem;
  margin: ${spacing.lg}px 0 ${spacing.xs}px;
`;

const NestedUnorderedList = styled(UnorderedList)`
  list-style-type: disc;
  margin-top: 0;
`;

const SubButton = styled(Button)`
  box-shadow: 0 0 0 1px #b84076;
  `;

class Faq extends React.Component {
  constructor() {
    super();
    this.state = {}
  }

  render() {
    function renderFaqUl(items) {
      return (<ul>{items.map((item) => <li dangerouslySetInnerHTML={{__html: item}}></li>)}</ul>)
    };

    function renderFaqHeading(item, HeadingClass=SectionHeading) {
      return (
        <>
          <HeadingClass >{item.heading}</ HeadingClass>
          {item.body && <span dangerouslySetInnerHTML={{__html: item.body}}></span>}
          {item.subheadings && item.subheadings.map((subItem, idx) => {
            return renderFaqHeading(subItem, SubHeading)
          })}
          {item.ul && renderFaqUl(item.ul)}
          {item.buttons && item.buttons.map((btn) => {
            return (
              <p>
                <SubButton href={btn.href} target={btn.target || "_blank"}>{btn.body}</SubButton>
              </p>
            )
          })}
        </>
      )
    };


    return (
      <InterfaceContext.Consumer>
        {({ vendorConfig }) => {
          const faqItems = vendorConfig.messages.en.faq || []
          return (
            <ProductTextContainer>
              <Heading>Frequently Asked Questions (FAQ) </Heading>
              <BackButton>Go Back</BackButton>
              <Section id="questions">
                {faqItems.map((item, idx) => {
                  return renderFaqHeading(item)
                })}
              </Section>
            </ProductTextContainer>
          )
        }}
      </InterfaceContext.Consumer>

    );
  }
}

export default Faq;
